<template>
  <v-card @click="GroupDetails(group.id)" :class="group.number.split(' ')[0]" class="card">
    <section class="date">
      <img v-if="group.number.split(' ')[0] == 'FSP' || group.number.split(' ')[0] == 'FSD'" src="@/assets/python.png" width="75%" />
      <img v-if="group.number.split(' ')[0] == 'AI'" src="@/assets/ai.png" width="75%" />
      <img v-if="group.number.split(' ')[0] == 'GD'" src="@/assets/gd.png" width="75%" />
    </section>
    <section class="card-cont">
      <span class="text-caption d-block">Группа</span>
      <span class="text-h6 text-sm-h5 text-md-h5 text-lg-h4 text-xl-h4">{{ group.number }}</span>
      <div class="teacher">
        <span class="text-caption d-block">{{ teacher_name }}</span>
      </div>
      <div class="mt-3">
        <v-icon color="white" left>mdi-account-multiple</v-icon>
        <span class="text-caption">{{ group.students.length }}</span>
      </div>
    </section>
  </v-card>
</template>

<script>
export default {
  name: "ticket",
  props: {
    group: "",
    teacher_name: {},
  },
  methods: {
    GroupDetails(id) {
      this.$router.push({ name: "GroupDetails", params: { gid: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  max-width: 400px;
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
  border-radius: 12px;
  position: relative;
}

.GD{
  background: radial-gradient(
    circle,
    #FF8A80 21%,
    #F44336 100%
  );
}

.FSP{
  background: radial-gradient(
    circle,
    rgb(103, 164, 255) 21%,
    rgb(44, 111, 178) 100%
  );
}

.FSD{
  background: radial-gradient(
    circle,
    rgb(121, 255, 103) 21%,
    rgb(60, 178, 44) 100%
  );
}

.AI{
  background: radial-gradient(
    circle,
    #FFFF8D  21%,
    #FFEB3B 100%
  );
}

.date {
  display: table-cell;
  width: 140px;
  position: relative;
  text-align: center;
  border-right: 2px dashed #fff;
  min-height: 150px;
  border-radius: 0 !important;
}

.date:before,
.date:after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: #fff   ;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 1;
  border-radius: 50%;
}

.date:after {
  top: auto;
  bottom: -15px;
}

.date img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.card-cont {
  display: table-cell;
  width: 60%;
  font-size: 85%;
  padding: 10px 10px 30px 50px;
  min-height: 150px;
}

@media screen and (max-width: 860px) {
  .card {
    display: block;
    float: none;
    width: 100%;
    margin-bottom: 10px;
  }
  .card + .card {
    margin-left: 0;
  }
  .card-cont .even-date,
  .card-cont .even-info {
    font-size: 75%;
  }
}
</style>>
